<template>
  <BaseDialog
    :propDialog="dialog"
    :title="title"
    :validity="valid"
    :loader="loading"
    @confirm="submitDish"
    @closeDialog="setDialog"
  >
    <v-container class="px-0">
      <v-form ref="form" v-model="valid" @submit.prevent>
        <BaseSwitch
          :label="
            selectedDish.serving ? 'dish.show-in-menu' : 'dish.hide-in-menu'
          "
          v-model="selectedDish.serving"
        />
        <BaseTextInput
          label="dish.vi-name"
          v-model="selectedDish.viName"
          required
          :character-limit="99"
        />
        <BaseTextInput
          label="dish.en-name"
          v-model="selectedDish.enName"
          :character-limit="99"
        />
        <v-row dense>
          <v-col cols="7">
            <BaseDropdown
              label="dish.category-dropdown"
              no-data="dish.no-category"
              v-model="selectedDish.category"
              :items="dishCategories"
              :item-text="$i18n.locale === 'vi' ? 'viName' : 'enName'"
              item-value="categoryCode"
              required
            />
          </v-col>
          <v-col cols="5">
            <BaseCheckbox
              label="dish.from-kitchen"
              v-model="selectedDish.fromKitchen"
            />
          </v-col>
          <v-col cols="7">
            <BaseCheckbox
              label="dish.price-TBD"
              v-model="selectedDish.priceTBD"
            />
          </v-col>
          <v-col cols="5">
            <BaseCheckbox
              label="dish.in-kilogram"
              v-model="selectedDish.inKilogram"
            />
          </v-col>
          <v-col v-if="!update && !selectedDish.priceTBD" cols="12">
            <BaseCurrencyInput
              outlined
              label="dish.price"
              v-model="selectedDish.price"
              currency="VND"
            />
          </v-col>
        </v-row>
        <BaseFileInput label="dish.dish-image" v-model="file" />
        <v-row justify="center">
          <v-col cols="12" sm="8" lg="6" class="py-0">
            <BaseImagePreview
              :imgUrl="selectedDish.imgUrl"
              :imgFile="file"
              ref="imagePreview"
            />
          </v-col>
        </v-row>
        <BaseTextArea
          label="dish.description"
          v-model="selectedDish.description"
          :character-limit="99"
          rows="3"
        />
      </v-form>
    </v-container>
  </BaseDialog>
</template>

<script>
import { mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { displayErrorMessage, createForm } from '@/utils'

export default {
  name: 'DishDetails',

  props: {
    dialog: Boolean,
    dish: Object,
    update: Boolean
  },

  data: () => ({
    loading: false,
    valid: true,
    file: null,
    selectedDish: null,
    title: ''
  }),

  computed: {
    ...mapState('dish', ['dishCategories'])
  },

  watch: {
    dish: {
      immediate: true,
      handler: function(val) {
        this.selectedDish = cloneDeep(val)
        if (this.update) {
          this.title = 'dish.update-dish'
        } else {
          this.title = 'dish.add-dish'
        }
      }
    },

    'selectedDish.priceTBD'() {
      this.selectedDish.price = null
    },

    file(val) {
      if (val)
        this.$nextTick().then(() => scrollIntoView(this.$refs.imagePreview.$el))
    }
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    submitDish() {
      const form = createForm(this.selectedDish, this.file)
      const dishPrice = form.get('price')
      if (isNaN(dishPrice)) {
        form.set('price', 0)
      }
      this.loading = true
      const submit = () => {
        if (!this.update) {
          return this.$store.dispatch('dish/createDish', form)
        } else {
          return this.$store.dispatch('dish/updateDish', {
            id: this.selectedDish._id,
            form
          })
        }
      }

      submit()
        .then(() => {
          this.setDialog(false)
        })
        .catch(err => displayErrorMessage(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style scoped></style>
